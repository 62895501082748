.button-language {
  position: relative;
  z-index: 10;
  width: auto;

  margin-top: auto;
  margin-left: auto;
  margin-bottom: 10vh;

  &_title {
    @include font(14, 12, 400, var(--menu_bottom_title-color));
    @include pxToEm(padding, 10);

    text-align: center;
    margin-bottom: 1px;

    border-top: var(--menu_bottom_title-border);

    @media (max-width: 992px) {
      color: var(--menu_bottom_title-color_mobile);
    }
  }

  .flag {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 10px;
    border-bottom: 1px solid #ffffff38;

    transform-origin: center;
    transition: background 0.4s ease;

    &:last-child {
      margin-bottom: 0;
      border-bottom: none;
    }

    .name {
      display: flex;
      align-items: center;
      overflow: hidden;
      color: #fff;

      transition: transform 0.4s ease;
      font-size: 14px;
      font-weight: 500;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .image {
      min-width: 20px;
      width: 20px;
      height: auto;
      // margin-right: 10px;

      transition: transform 0.4s ease;
    }

    .image+.name {
      margin-left: 10px;
    }
  }

  .trigger {
    border: var(--lang-button_border, solid 1px #fff);
    transition: 0.4s ease 0ms;
    cursor: pointer;
    border-radius: 5px;
    background: var(--lang-button_bg, #000);

    &:hover {
      background: var(--lang-button_bg-hover, rgb(43 47 84 / 90%));
    }

    .image {
      width: 20px;
      height: auto;
    }

    .arrow {
      width: 16px;
      height: 16px;
      margin-left: 5px;
      padding: 3px;
      transition: 0.4s ease transform;
    }
  }

  .list {
    width: 100%;

    max-height: 200px;
    position: absolute;
    background: #000;
    border: 1px solid #fff;
    bottom: 100%;
    left: 0;
    overflow: hidden scroll;

    transition: 0.4s ease 0ms;
    border-radius: 5px 5px 0 0;

    opacity: 0;
    visibility: hidden;
    pointer-events: none;

    scrollbar-width: none; // For Firefox

    &::-webkit-scrollbar {
      display: none;
    }

    .flag {
      &:hover {
        background: #0b0b0b;

        .name,
        .image {
          transform: scale(1.1);
        }
      }
    }
  }

  &[data-opened='true'] {
    .trigger {
      border-radius: 0 0 5px 5px;

      .arrow {
        transform: rotate(180deg);
      }
    }

    .list {
      opacity: 1;
      visibility: visible;
      pointer-events: all;
    }
  }
}
